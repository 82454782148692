import { decrypt, encrypt } from './crypt';

interface Storage {
  get<T>(key: string, nestedKeyPath?: string, passphrase?: string): T | null;
  set<T>(key: string, value: T, nestedKeyPath?: string, passphrase?: string): void;
  remove(key: string): void;
}

function getData(key: string, passphrase?: string): any {
  const storedData = localStorage.getItem(key);
  if (!storedData) return null;
  return passphrase ? JSON.parse(decrypt(passphrase, storedData)) : JSON.parse(storedData);
}

function saveData(key: string, data: any, passphrase?: string): void {
  const serializedData = JSON.stringify(data);
  const encryptedData = passphrase ? encrypt(passphrase, serializedData) : serializedData;
  localStorage.setItem(key, encryptedData);
}

function setOrUpdateNestedValueAtPath(data: any, nestedKeyPath: string, value: any): void {
  const keys = nestedKeyPath.split('.');
  let obj = data;
  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    obj[key] = obj[key] || {};
    obj = obj[key];
  }
  obj[keys[keys.length - 1]] = value;
}

function getNestedValueAtPath(data: any, nestedKeyPath: string): any {
  const keys = nestedKeyPath.split('.');
  let obj = data;
  for (const key of keys) {
    if (!Object.prototype.hasOwnProperty.call(obj, key)) return null;
    obj = obj[key];
  }
  return obj;
}

export const storage: Storage = {
  get<T>(key: string, nestedKeyPath?: string, passphrase?: string): T | null {
    const storedData = getData(key, passphrase);

    if (!nestedKeyPath) {
      return storedData || null;
    }

    return storedData ? getNestedValueAtPath(storedData, nestedKeyPath) : null;
  },

  set<T>(key: string, value: T, nestedKeyPath?: string, passphrase?: string): void {
    let storedData = getData(key, passphrase);

    if (!storedData) {
      storedData = {};
    }

    if (!nestedKeyPath) {
      storedData = value;
    } else {
      setOrUpdateNestedValueAtPath(storedData, nestedKeyPath, value);
    }

    saveData(key, storedData, passphrase);
  },

  remove(key: string): void {
    localStorage.removeItem(key);
  },
};
