<script setup lang="ts">
import { ref } from 'vue';
import { ModalSetup, type ModalEmits, type ModalProps } from './modal';

const props = withDefaults(defineProps<ModalProps>(), {
  size: 'md',
  outside: true,
});

const emit = defineEmits<ModalEmits>();

const dialog = ref<HTMLDialogElement | null>(null);

const { show, cancel, confirm, close } = ModalSetup(dialog, props, emit);
</script>

<template>
  <dialog :id="modal" ref="dialog" :class="`dialog-${size}`" class="p-0" @click.prevent="close()">
    <div @click.stop="" method="dialog" class="w-full h-full p-5">
      <slot v-bind="{ show, confirm, close, cancel }" />
    </div>
  </dialog>
</template>

<style scoped lang="scss">
.flex {
  display: flex;
  flex-direction: row-reverse;
  margin: 0.5rem;
}

.dialog {
  .container {
    padding: 15px;
  }

  &-sm {
    width: 40%;
  }

  &-md {
    width: 60%;
  }

  &-lg {
    width: 80%;
  }

  &-full {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    margin: 0;
    border-radius: 0;
    padding: 0;
  }

  @media screen and (max-width: 998px) {

    &-sm,
    &-md,
    &-lg {
      width: 90%;
    }
  }
}

dialog {
  border: none;
  border-radius: 6px;
  opacity: 0;
  transform: scaleY(0);
  transition: all 300ms allow-discrete;
}

dialog[open] {
  opacity: 1;
  transform: scaleY(1);
}

@starting-style {
  dialog[open] {
    opacity: 0;
    transform: scaleY(0);
  }
}

dialog::backdrop {
  background-color: rgb(0 0 0 / 0%);
  transition: all 300ms allow-discrete;
}

dialog[open]::backdrop {
  background-color: rgb(0 0 0 / 25%);
}

@starting-style {
  dialog[open]::backdrop {
    background-color: rgb(0 0 0 / 0%);
  }
}
</style>
