import { onMounted, ref, shallowReactive, type Ref, type ShallowRef } from 'vue';
import { api } from '@/services/api';
import { storage } from '@/services/storage';
import { useModal } from '@/components/modal/modal';
import { STEP_STORAGE_KEY } from '@/services/step';

import type { Question } from '@/types/question';
import type { Result } from '@/types/result';

export interface Form {
  name: string;
  zipcode: string;
  company_name: string;
}

interface Questionare {
  form: Form;
  questions: Ref<Question[]>;
  result: Result;
  store: (form: Form) => Promise<Result>;
  update: (choice: ShallowRef<Number>) => Promise<Result>;
  destroy: (id: string) => void;
  send: (id: string, value: string) => void;
}

export const RESULT_STORAGE_KEY = '_results';
export const MAIL_SUCCESS_MODALKEY = 'success';
export const INFORMATION_SECTION_LENGHT = 4;

const { open } = useModal();

const questions = ref<Question[]>([]);
export const sendLoading = ref<boolean>(false);

export function questionaire(result: Result): Questionare {
  const form = shallowReactive<Form>({ name: '', company_name: '', zipcode: '' });

  async function create() {
    try {
      const { data } = await api.get<Question[]>('questionaire/create');
      if (!data) {
        questions.value = [];
        return;
      }

      questions.value = data;
    } catch (error) {
      console.error(error);
    }
  }

  async function store(form: Form) {
    try {
      const { data } = await api.post<Result>('questionaire', form);
      if (!data) return;

      storage.set(RESULT_STORAGE_KEY, data);

      result = data;
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async function update(choice: ShallowRef<number>, previous?: number) {
    try {
      const { data } = await api.patch<Result>(`questionaire/${result.id}`, { choice: choice.value, previous });
      if (!data) return;

      storage.set(RESULT_STORAGE_KEY, data);

      result = data;
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async function destroy(id: string) {
    try {
      await api.delete<void>(`questionaire/${id}`);

      form.name = '';
      form.zipcode = '';
      form.company_name = '';
      result = null;
      questions.value = null;

      document.location.reload();
    } catch (error) {
      console.error(error);
    } finally {
      storage.remove(RESULT_STORAGE_KEY);
      storage.remove(STEP_STORAGE_KEY);
    }
  }

  async function send(id: string, email: string) {
    sendLoading.value = true;

    try {
      const { data, status } = await api.patch<Result>(`questionaire/${id}/share`, { email });
      if (status === 200) open(MAIL_SUCCESS_MODALKEY);
      if (!data) return;

      storage.set(RESULT_STORAGE_KEY, data);

      result = data;
      return data;
    } catch (error) {
      console.error(error);
    } finally {
      sendLoading.value = false;
    }
  }

  onMounted(async () => await create());

  return {
    form,
    result,
    questions,
    store,
    update,
    destroy,
    send,
  };
}
