<script setup lang="ts">
import { onMounted, ref } from 'vue';

const value = defineModel<string | null>({ required: true });
defineProps<{
  type: string;
  index: number;
}>();

const infoElement = ref<HTMLInputElement>();
onMounted(() => infoElement.value?.focus());
</script>

<template>
  <div class="section__content">
    <div class="block block--question">
      <span class="block__index">{{ index }}</span>
      <div class="block__content">
        <label for="start">Wat is {{ type }}?</label>
        <input v-model="value" type="text" id="start" :placeholder="`Type hier ${type}`" class="start--input" />
      </div>
    </div>
  </div>
</template>
