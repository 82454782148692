import { ref, watchEffect, type Ref } from 'vue';

import './polyfill';

export interface ModalProps {
  modal: string;
  size?: 'sm' | 'md' | 'lg' | 'full';
  outside?: boolean;
}

export interface ModalEmits {
  (e: 'update:modal', value?: unknown): void;
  (e: 'cancel'): void;
}

export interface UseModal {
  visible: Ref<boolean>;
  open: (key: string) => void;
  close: () => void;
}

export interface ModalType {
  show: () => void;
  confirm: () => void;
  cancel: () => void;
  close: (val?: string) => void;
}

const modalKey = ref<string>('');
const visible = ref<boolean>(false);
const closeModal = ref<boolean>(false);

export function ModalSetup(dialog: Ref<HTMLDialogElement | null>, props: ModalProps, emits: ModalEmits): ModalType {
  function show() {
    dialog.value?.showModal();
  }

  function cancel() {
    if (props.outside === false) return;
    dialog.value?.close();
    modalKey.value = '';
    closeModal.value = false;
    emits('cancel');
  }

  function confirm() {
    dialog.value?.close();
    emits('update:modal');
  }

  function close(returnVal?: string) {
    if (props.outside === false) return;
    dialog.value?.close(returnVal);
    modalKey.value = '';
    closeModal.value = false;
  }

  watchEffect(() => {
    modalKey.value === dialog.value?.id ? show() : null;
    visible.value = dialog.value?.open ?? false;
    closeModal.value ? close() : null;
  });

  return {
    show,
    cancel,
    confirm,
    close,
  };
}

export function useModal(): UseModal {
  function open(key: string) {
    modalKey.value = key;
  }

  function close() {
    closeModal.value = true;
  }

  return {
    open,
    close,
    visible,
  };
}
