<script setup lang="ts">
import type { Question } from '@/types/question';
import { useModal } from '@/components/modal/modal';

import QuestionInformation from './modals/QuestionInformation.vue';
import Popup from './modals/Popup.vue';

const { open, visible } = useModal();

const props = defineProps<{
  question: Question;
  index: number;
}>();

defineEmits<{ (e: 'restart'): void }>();

const [value] = defineModel<number | null>({
  set(value) {
    const choice = props.question.choices.find(({ id }) => id === value);
    if (choice && choice.next_id === null && choice && choice.popup !== null) {
      openPopup(choice.id);
      return;
    } else if (choice && choice.popup !== null) {
      openPopup(choice.id);
      return value;
    }

    return value;
  },
  required: true,
});

function keyMap(event: Event & KeyboardEvent) {
  if (!(event instanceof KeyboardEvent)) return;
  if (!props.question.choices.map((_, idx) => idx + 1).includes(+event.key)) return;
  if (visible.value) return;

  value.value = props.question.choices[+event.key - 1].id;
}

document.addEventListener('keydown', keyMap);

const INFORMATION_MODALKEY = 'question-information';
const POPUP_MODALKEY = 'question-popup';

function openPopup(index: number) {
  open(`${POPUP_MODALKEY}_${index}`);
}
</script>

<template>
  <div class="section__content">
    <div class="block block--question">
      <span class="block__index"> {{ index }}. </span>
      <div class="block__content">
        <div class="flex">
          <label class="label" for="#">
            {{ question.name }}
          </label>
          <template v-if="question.information">
            <a class="info" @click.prevent="open(INFORMATION_MODALKEY)">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Group">
                  <g id="Group_2">
                    <path id="Path"
                      d="M15.9988 10.6666C15.8148 10.6666 15.6655 10.8159 15.6668 10.9999C15.6668 11.1839 15.8162 11.3333 16.0002 11.3333C16.1842 11.3333 16.3335 11.1839 16.3335 10.9999C16.3335 10.8159 16.1842 10.6666 15.9988 10.6666"
                      stroke="#085CF0" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path id="Path_2" fill-rule="evenodd" clip-rule="evenodd"
                      d="M16 28V28C9.372 28 4 22.628 4 16V16C4 9.372 9.372 4 16 4V4C22.628 4 28 9.372 28 16V16C28 22.628 22.628 28 16 28Z"
                      stroke="#085CF0" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path id="Path_3" d="M16.0002 16V22.6667" stroke="#085CF0" stroke-width="2.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </g>
                </g>
              </svg>
            </a>
          </template>
        </div>
        <div class="grid grid--two">
          <template v-for="(choice, idx) in question.choices" :key="idx">
            <div class="check">
              <input type="radio" :id="`option-${idx}`" name="question" v-model="value" :value="choice.id" />
              <label :for="`option-${idx}`">
                <span class="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.333 11.667">
                    <path fill="none" stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"
                      d="m1.5 5.5 4.667 4.667L14.833 1.5" />
                  </svg>
                </span>
                <span class="text"> {{ choice.name }} </span>
                <span class="key">{{ idx + 1 }}</span>
              </label>
            </div>
            <Popup :modal-key="`${POPUP_MODALKEY}_${choice.id}`" :choice="choice" @restart="$emit('restart')" />
          </template>
        </div>
      </div>
    </div>
  </div>
  <QuestionInformation :modalKey="INFORMATION_MODALKEY" :question="question" />
</template>

<style scoped>
.flex {
  display: flex;
  justify-content: space-between;
  margin: 0 0 30px 0;
}

.label {
  align-self: center;
  margin: 0;
}

.info {
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  border-radius: 143px;
  background: #f4f4f4;
  padding: 1rem;
  height: 30px;
  width: 30px;
}
</style>
